import React from 'react';
import {Paper} from '@material-ui/core';
import TransferCardForm from './TransferCardForm';
import TextInput from '../textInpit/TextInput';
import WithTranslator from '../../providers/WithTranslator';
import FieldsHelper from './FieldsHelper';
import FValidator from './FieldsValidator';
import {AMOUNT_LIMITATION} from "../../constants";


class TransferSendFirstStep extends React.Component {
    constructor(props) {
        super(props);
        this.FHelper = new FieldsHelper(this, this.updateFields.bind(this));
        const fields = this.props.fields;
            this.state = {
            fields,
            errors: {},
            shouldCheckIsBinMatch: false,
        };
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.fields) !== JSON.stringify(this.props.fields)) {
            this.setState({
                fields: this.props.fields,
                errors: {}
            });
        }
    }
    updateFields = (updatedFields) => {
        this.setState({ fields: updatedFields }, () => {
            if (this.props.onUpdate) {
                const errors = {
                    amountError: !FValidator.isAmountValid(this.state.fields.amount),
                    cardError: !FValidator.isCardValid(this.state.fields.card),
                    ownerError: !FValidator.isOwnerValid(this.state.fields.owner),
                    monthError: !FValidator.isMonthValid(this.state.fields.month),
                    yearError: !FValidator.isYearValid(this.state.fields.year),
                    cvvError: !FValidator.isCvvValid(this.state.fields.cvv),
                };
                this.props.onUpdate(updatedFields, errors);
            }
        });
    }

    render() {
        const translator = this.props.translator;
        return (
            <>
                <Paper className='margin-bottom amount'>
                    <span className='h3 margin-bottom'>{translator('transferPart.form.amount.title')}</span>
                    <div className="error">{this.state.errors.amountError && translator('transferPart.form.amount.limitationError')(AMOUNT_LIMITATION.MIN, AMOUNT_LIMITATION.MAX)}</div>
                    <div>
                        <TextInput label={translator('transferPart.form.amount.placeholder')}
                                   value={this.state.fields.amount}
                                   onChange={this.FHelper.onAmountChange}
                                   onBlur={this.FHelper.onAmountBlur}/>
                        <span className={` ${this.state.errors.amountError ? 'currencyWithError' : 'currencyWithoutError'}`}>₽</span>
                    </div>
                </Paper>

                <TransferCardForm
                    storage={this}
                    title={translator('transferPart.form.card1.title')}
                    withCvv={true}
                    withDate={true}
                    onUpdate={this.updateFields}
                    checkBinMatch={this.state.shouldCheckIsBinMatch}
                />


            </>
        )
    }
}

export default WithTranslator(TransferSendFirstStep);
