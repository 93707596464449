import React from 'react';
import TransferSendFirstStep from './TransferSendFirstStep';
import TransferSendSecondStep from './TransferSendSecondStep';
import TransferResultStep from './TransferResultStep';
import TransferFreeDS from './TransferFreeDS';
import WithTranslator from '../../providers/WithTranslator';
import apiService from '../../services/apiService';
import {RESULT_STATUS as STATUS} from '../../constants';
import {Button, Checkbox, Paper} from "@material-ui/core";

export const defaultFirstFields = {
    amount: '',
    card: '',
    owner: '',
    month: '',
    year: '',
    cvv: '',
    agree: false,
    deferPayout: true,
};

export const defaultSecondFields = {
    card: '',
    owner: '',
};

class TransferSend extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            _3ds: null,
            trId: null,
            step: 1,
            firstFields: defaultFirstFields,
            secondFields: defaultSecondFields,
            status: STATUS.FORM,
            firstFieldErrors: {},
            secondFieldErrors: {}
        };

    }

    componentDidMount() {
        const trId = window.location.href.split('?trId=')[1];
        if (trId) {
            this.setState({status: STATUS.WAIT}, () => {
                window.scrollTo({
                    top: document.getElementById('transferResult').offsetTop - 80,
                });
                this.sendQuery(() => apiService.getStatus(decodeURIComponent(trId)));
            });
        }
        this.part = document.getElementById('transferPart');
    }

    goUp = () => window.scrollTo({top: this.part.offsetTop - 88, behavior: 'smooth'});

    /*    onSubmitFirstStep = fields => {
            this.goUp();
            if (fields.deferPayout) {
                const data = this.getData(fields);
                this.setState({status: STATUS.WAIT, firstFields: {...fields}});
                this.sendQuery(() => apiService.startTransfer(data));
            } else {
                this.setState({step: 2, firstFields: {...fields}});
            }
        }*/

    onSubmit = () => {
        this.goUp();
        const data = this.getData(this.state.firstFields);
        /*data['to'] = {
            'cardNumber': this.state.secondFields.card.replaceAll(' ', ''),
            'cardHolder': this.state.secondFields.owner,
            'expMonth': +this.state.secondFields.month,
            'expYear': +this.state.secondFields.year,
        };
        this.setState({status: STATUS.WAIT, secondFields: this.state.secondFields});
        this.sendQuery(() => apiService.startTransfer(data));*/
        this.setState({status: STATUS.WAIT, firstFields: this.state.firstFields});
        this.sendQuery(() => apiService.startTransfer(data));
    };

    onCancel = () => {
        this.goUp();
        this.setState({
            firstFields: {...defaultFirstFields}, secondFields:{...defaultSecondFields},
            firstFieldErrors: {}, secondFieldErrors:{},
        });
    };

    sendQuery = startPromise => startPromise().then(state => this.setState({...state}));

    getData = firstFields => ({
        'deferPayout': firstFields.deferPayout,
        'amount': {
            'value': +firstFields.amount,
            'currency': 'RUB',
        },
        'from': {
            'cardNumber': firstFields.card.replaceAll(' ', ''),
            'cardHolder': firstFields.owner,
            'expMonth': +firstFields.month,
            'expYear': +firstFields.year,
            'cvc': +firstFields.cvv,
        },
        '3ds': {
            'redirectUri': window.location.href,
        },
    });

    onStart = () => {
        this.goUp();
        const state = this.state.status === STATUS.ERROR
            ? {status: STATUS.FORM}
            : {
            status: STATUS.FORM, firstFields: defaultFirstFields, secondFields: defaultSecondFields,
            firstFieldErrors: {}, secondFieldErrors: {}
        };
        this.setState(state);
        window.history.pushState({}, null, '/');
    };

    handleUpdateFirstFields = (newFields, errors) => {
        this.setState({firstFields: newFields, firstFieldErrors: { ...errors}});
    };

    handleUpdateSecondFields = (newFields, errors) => {
        this.setState({secondFields: newFields,  secondFieldErrors: { ...errors}});
    };

    render() {
        const translator = this.props.translator;
        const commission = this.state.firstFields.amount < 200
            ? this.state.firstFields.amount * 0.015
            : this.state.firstFields.amount * 0.02;
        return (
            <>
                <form>
                    {this.state.status === STATUS.FORM &&
                        <>
                            <TransferSendFirstStep fields={this.state.firstFields}
                                                   onUpdate={this.handleUpdateFirstFields}
                            />
                            <TransferSendSecondStep fields={this.state.secondFields}
                                                    onUpdate={this.handleUpdateSecondFields}
                            />
                            <Paper className="commission margin-bottom">
                                {translator('transferPart.form.commission1')}
                                <span>{commission.toFixed(2)}</span>
                                {translator('transferPart.form.commission2')}
                            </Paper>

                            <div className="agree-part margin-bottom">
                                <Checkbox checked={this.state.firstFields.agree}
                                          onChange={() =>
                                              this.setState({
                                                  firstFields: {
                                                      ...this.state.firstFields,
                                                      agree: !this.state.firstFields?.agree,
                                                  },
                                              })
                                          } />
                                <span>
                                    {translator('transferPart.form.agree1')}&nbsp;
                                    {/*<a href="" target="_blank">*/}
                                    {translator('transferPart.form.agree2')}
                                    {/*</a>*/}
                                 </span>
                            </div>

                            <div className="controls">
                                <Button className="primary _large" onClick={this.onSubmit}
                                        disabled={
                                            Object.values(this.state.firstFieldErrors).some(e => !!e) ||
                                            Object.values(this.state.secondFieldErrors).some(e => !!e) ||
                                            !Object.values(this.state.firstFields).every(f => !!f) ||
                                            !Object.values(this.state.secondFields).every(f => !!f) ||
                                            !this.state.firstFields.agree
                                        }>
                                    {translator('transferPart.form.btn2.submit')}
                                </Button>
                                <Button className="secondary _large" onClick={this.onCancel}>
                                    {translator('transferPart.form.btn1.cancel')}
                                </Button>
                            </div>
                        </>
                    }

                    {this.state.status !== STATUS.FORM &&
                        <TransferResultStep status={this.state.status}
                                            onSubmit={this.onStart}
                                            trId={this.state.trId} />}
                </form>

                <TransferFreeDS _3ds={this.state._3ds} />
            </>
        );
    }
}

export default WithTranslator(TransferSend);
