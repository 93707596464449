import React from 'react';
import {Paper} from '@material-ui/core';
import WithTranslator from '../../providers/WithTranslator';

import './CommissionPart.scss';
import {AMOUNT_LIMITATION} from "../../constants";

class CommissionPart extends React.Component {
    render() {
        const translator = this.props.translator;
        return (
            <div className='commission-part' id='commissionPart'>
                <span className='h2'>{translator('commissionPart.title')}</span>
                <div className='commission-part__inner'>
                    {[
                        {
                            title: translator('commissionPart.card1.title')(AMOUNT_LIMITATION.MIN),
                            text: translator('commissionPart.card1.text')
                        },
                        {
                            title: translator('commissionPart.card2.title'),
                            text: translator('commissionPart.card2.text')
                        },
                    ].map((item, i) =>
                        <Paper className='card' key={i}>
                            <div>
                            <span className='h3 title'>
                                {item.title}
                            </span>
                                {item.text}
                            </div>
                        </Paper>
                    )}
                </div>
            </div>
        )
    }
}

export default WithTranslator(CommissionPart);