import {RestRequest} from './requestService';
import {PAYOUT_STATUS, RESULT_STATUS} from '../constants';

const TIMEOUT = 500;

const executeTransferRequest = (url, data) =>
    RestRequest.post(url, {}, data)
        .then(response => {
            const trId = response.data?.trId;
            trId && history.pushState(null, null, `?trId=${encodeURIComponent(trId)}`);
            if (response.data.status === PAYOUT_STATUS.PENDING) {
                return new Promise(resolve =>
                    setTimeout(() => resolve(getStatus(trId)), TIMEOUT));
            }
            return getProcessedResponse(response);
        })
        .catch(reason => reason?.response?.status
            ? getProcessedResponse(reason)
            : new Promise(resolve =>
                setTimeout(() => resolve(executeTransferRequest(url, data)), TIMEOUT))
        );

const getProcessedResponse = response => {
    switch (response.data?.status) {
        case PAYOUT_STATUS.WAITING_3DS:
            const _3ds = response.data['3ds'];
            return {_3ds};
        case PAYOUT_STATUS.TRANSFERED:
            return {status: RESULT_STATUS.SUCCESS, trId: response.data.trId};
        case PAYOUT_STATUS.WAITING_CONTINUE_TRANSFER:
            return {status: RESULT_STATUS.SUCCESS, trId: response.data.trId};
            // return {status: RESULT_STATUS.CODE, trId: response.data.trId};
        case PAYOUT_STATUS.FAILED:
        case PAYOUT_STATUS.FAILED_CONTINUE_TRANSFER:
        default:
            return {status: RESULT_STATUS.ERROR};
    }
}

const sendFeedback = data =>
    RestRequest.post(configs.apiUrl + '/sendFeedback', {}, data);

const startTransfer = data => executeTransferRequest(configs.apiUrl + '/startTransfer', data);

const continueTransfer = data => executeTransferRequest(configs.apiUrl + '/continueTransfer', data);

const getStatus = trId => executeTransferRequest(configs.apiUrl + '/getStatus', {trId});

export default {
    sendFeedback,
    startTransfer,
    continueTransfer,
    getStatus,
}
