import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import {IconButton, Paper} from '@material-ui/core';
import WithTranslator from '../../providers/WithTranslator';

import './QuestionsPart.scss';
import plus from '../../resources/plus.svg';
import minus from '../../resources/minus.svg';
import {AMOUNT_LIMITATION} from "../../constants";

class QuestionsPart extends React.Component {
    constructor(props) {
        super(props);

        this.isFullMode = document.documentElement.clientWidth > 1200;
        this.state = {
            card0: this.isFullMode,
            card1: this.isFullMode,
            card2: this.isFullMode
        }
    }

    render() {
        const translator = this.props.translator;
        return (
            <div className='questions-part' id='questionsPart'>
                <span className='h2'>{translator('questionsPart.title')}</span>
                {[
                    {
                        title: translator('questionsPart.card1.title'),
                        text: translator('questionsPart.card1.text')
                    },
                    {
                        title: translator('questionsPart.card2.title'),
                        text: translator('questionsPart.card2.text')(AMOUNT_LIMITATION.MIN, AMOUNT_LIMITATION.MAX_TEXT)
                    },
                    {
                        title: translator('questionsPart.card3.title'),
                        text: translator('questionsPart.card3.text')
                    },
                ].map((item, i) =>
                    <Paper className={`card ${this.state[`card${i}`] ? '' : '_hidden'}`} key={i}>
                        <div>
                            <a
                                className={`h3 title ${this.isFullMode ? '' : '_clickable'}`}
                                onClick={() => !this.isFullMode && this.setState({[`card${i}`]: !this.state[`card${i}`]})}
                            >
                                {item.title}
                                <IconButton
                                    onClick={() =>
                                        this.setState({[`card${i}`]: !this.state[`card${i}`]})}
                                >
                                        <img src={this.state[`card${i}`] ? minus : plus} alt=''/>
                                </IconButton>
                            </a>
                            <span className='text'>{ReactHtmlParser (item.text)}</span>
                        </div>
                    </Paper>
                )}
            </div>
        )
    }
}

export default WithTranslator(QuestionsPart);
