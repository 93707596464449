import React from 'react';
import TransferCardForm from './TransferCardForm';
import WithTranslator from '../../providers/WithTranslator';
import FValidator from './FieldsValidator';

const defaultFields = {
    card: '',
    owner: '',
};

class TransferSendSecondStep extends React.Component {
    constructor(props) {
        super(props);

        const fields = this.props.fields ? {...this.props.fields} : {...defaultFields};
        this.state = {
            fields,
            errors: {},
            shouldCheckIsBinMatch: true,
        };
    }

    updateFields = (updatedFields) => {
        this.setState({ fields: updatedFields }, () => {
            if (this.props.onUpdate) {
                const errors = {
                    cardError: !FValidator.isCardValid(this.state.fields.card),
                    ownerError: !FValidator.isOwnerValid(this.state.fields.owner),
                    cardBinError: !FValidator.isBinMatched(this.state.fields.card),
                };
                this.props.onUpdate(updatedFields, errors);
            }
        });
    }
    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.fields) !== JSON.stringify(this.props.fields)) {
            this.setState({
                fields: this.props.fields,
                errors: {}
            });
        }
    }

    render() {
        const translator = this.props.translator;
        return (
            <>
                <TransferCardForm
                    storage={this}
                    onUpdate={this.updateFields}
                    title={translator('transferPart.form.card2.title')}
                    checkBinMatch={this.state.shouldCheckIsBinMatch}
                />
            </>
        )
    }
}

export default WithTranslator(TransferSendSecondStep);
